// prefer default export if available
const preferDefault = m => m && m.default || m

exports.components = {
  "component---src-pages-404-js": () => import("./../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-about-me-js": () => import("./../src/pages/about/me.js" /* webpackChunkName: "component---src-pages-about-me-js" */),
  "component---src-pages-about-timeline-js": () => import("./../src/pages/about/timeline.js" /* webpackChunkName: "component---src-pages-about-timeline-js" */),
  "component---src-pages-colab-js": () => import("./../src/pages/colab.js" /* webpackChunkName: "component---src-pages-colab-js" */),
  "component---src-pages-development-brainrun-js": () => import("./../src/pages/development/brainrun.js" /* webpackChunkName: "component---src-pages-development-brainrun-js" */),
  "component---src-pages-development-cenote-js": () => import("./../src/pages/development/cenote.js" /* webpackChunkName: "component---src-pages-development-cenote-js" */),
  "component---src-pages-development-character-position-js": () => import("./../src/pages/development/character-position.js" /* webpackChunkName: "component---src-pages-development-character-position-js" */),
  "component---src-pages-development-cyclopt-bot-js": () => import("./../src/pages/development/cyclopt-bot.js" /* webpackChunkName: "component---src-pages-development-cyclopt-bot-js" */),
  "component---src-pages-development-cyclopt-js": () => import("./../src/pages/development/cyclopt.js" /* webpackChunkName: "component---src-pages-development-cyclopt-js" */),
  "component---src-pages-development-e-gov-js": () => import("./../src/pages/development/e-gov.js" /* webpackChunkName: "component---src-pages-development-e-gov-js" */),
  "component---src-pages-development-gh-downloader-js": () => import("./../src/pages/development/gh-downloader.js" /* webpackChunkName: "component---src-pages-development-gh-downloader-js" */),
  "component---src-pages-development-gh-metrics-js": () => import("./../src/pages/development/gh-metrics.js" /* webpackChunkName: "component---src-pages-development-gh-metrics-js" */),
  "component---src-pages-development-js": () => import("./../src/pages/development.js" /* webpackChunkName: "component---src-pages-development-js" */),
  "component---src-pages-development-js-starter-kit-js": () => import("./../src/pages/development/js-starter-kit.js" /* webpackChunkName: "component---src-pages-development-js-starter-kit-js" */),
  "component---src-pages-development-jssa-js": () => import("./../src/pages/development/jssa.js" /* webpackChunkName: "component---src-pages-development-jssa-js" */),
  "component---src-pages-development-kyrcha-info-js": () => import("./../src/pages/development/kyrcha-info.js" /* webpackChunkName: "component---src-pages-development-kyrcha-info-js" */),
  "component---src-pages-development-npm-miner-js": () => import("./../src/pages/development/npm-miner.js" /* webpackChunkName: "component---src-pages-development-npm-miner-js" */),
  "component---src-pages-development-performants-js": () => import("./../src/pages/development/performants.js" /* webpackChunkName: "component---src-pages-development-performants-js" */),
  "component---src-pages-development-rt-js": () => import("./../src/pages/development/rt.js" /* webpackChunkName: "component---src-pages-development-rt-js" */),
  "component---src-pages-development-seaf-js": () => import("./../src/pages/development/seaf.js" /* webpackChunkName: "component---src-pages-development-seaf-js" */),
  "component---src-pages-external-js": () => import("./../src/pages/external.js" /* webpackChunkName: "component---src-pages-external-js" */),
  "component---src-pages-index-js": () => import("./../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-machine-learning-js": () => import("./../src/pages/machine-learning.js" /* webpackChunkName: "component---src-pages-machine-learning-js" */),
  "component---src-pages-notes-js": () => import("./../src/pages/notes.js" /* webpackChunkName: "component---src-pages-notes-js" */),
  "component---src-pages-publications-js": () => import("./../src/pages/publications.js" /* webpackChunkName: "component---src-pages-publications-js" */),
  "component---src-pages-research-apis-js": () => import("./../src/pages/research/apis.js" /* webpackChunkName: "component---src-pages-research-apis-js" */),
  "component---src-pages-research-archive-js": () => import("./../src/pages/research/archive.js" /* webpackChunkName: "component---src-pages-research-archive-js" */),
  "component---src-pages-research-automl-js": () => import("./../src/pages/research/automl.js" /* webpackChunkName: "component---src-pages-research-automl-js" */),
  "component---src-pages-research-big-code-js": () => import("./../src/pages/research/big-code.js" /* webpackChunkName: "component---src-pages-research-big-code-js" */),
  "component---src-pages-research-bioinformatics-js": () => import("./../src/pages/research/bioinformatics.js" /* webpackChunkName: "component---src-pages-research-bioinformatics-js" */),
  "component---src-pages-research-cia-js": () => import("./../src/pages/research/cia.js" /* webpackChunkName: "component---src-pages-research-cia-js" */),
  "component---src-pages-research-js": () => import("./../src/pages/research.js" /* webpackChunkName: "component---src-pages-research-js" */),
  "component---src-pages-research-mertacor-js": () => import("./../src/pages/research/mertacor.js" /* webpackChunkName: "component---src-pages-research-mertacor-js" */),
  "component---src-pages-research-ml-src-js": () => import("./../src/pages/research/ml-src.js" /* webpackChunkName: "component---src-pages-research-ml-src-js" */),
  "component---src-pages-research-near-js": () => import("./../src/pages/research/near.js" /* webpackChunkName: "component---src-pages-research-near-js" */),
  "component---src-pages-research-software-quality-js": () => import("./../src/pages/research/software-quality.js" /* webpackChunkName: "component---src-pages-research-software-quality-js" */),
  "component---src-pages-research-tropical-js": () => import("./../src/pages/research/tropical.js" /* webpackChunkName: "component---src-pages-research-tropical-js" */),
  "component---src-pages-tags-js": () => import("./../src/pages/tags.js" /* webpackChunkName: "component---src-pages-tags-js" */),
  "component---src-templates-bits-list-js": () => import("./../src/templates/bits-list.js" /* webpackChunkName: "component---src-templates-bits-list-js" */),
  "component---src-templates-bits-post-js": () => import("./../src/templates/bits-post.js" /* webpackChunkName: "component---src-templates-bits-post-js" */),
  "component---src-templates-blog-list-js": () => import("./../src/templates/blog-list.js" /* webpackChunkName: "component---src-templates-blog-list-js" */),
  "component---src-templates-blog-post-js": () => import("./../src/templates/blog-post.js" /* webpackChunkName: "component---src-templates-blog-post-js" */),
  "component---src-templates-category-js": () => import("./../src/templates/category.js" /* webpackChunkName: "component---src-templates-category-js" */),
  "component---src-templates-resource-page-js": () => import("./../src/templates/resource-page.js" /* webpackChunkName: "component---src-templates-resource-page-js" */),
  "component---src-templates-tag-js": () => import("./../src/templates/tag.js" /* webpackChunkName: "component---src-templates-tag-js" */)
}

